

















import ForwardActionModel from "./ForwardActionModel";
import CheckChannelsList from "@/components/Post/CheckChannelsList/CheckChannelsList.vue";
import { ForwarMessageActionMode } from "@/includes/types/Post.types";
import { FlowsService } from "@/includes/services/FlowService";
import { errorNotification } from "@/includes/services/NotificationService";
import { FrontendTargetData } from "@/components/Post/CheckChannelsList/types";
import CheckChannelsListItem from "@/components/Post/CheckChannelsList/CheckChannelsListItem.vue";
import { rawTargetsToFrontend } from "@/components/Post/CheckChannelsList/logic";

import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  data() {
    return {
      ForwarMessageActionMode
    }
  },
  components: {
    CheckChannelsListItem,
    CheckChannelsList
  }
})
export default class ForwardActionView extends Vue {
  @Prop() model!: ForwardActionModel

  @Prop() disabled!: boolean

  frontendData: Array<IterableListItem<FrontendTargetData>> = []

  checkChannels(targets:Array<string> | Array<number>) {
    return FlowsService.checkChannels('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      values: targets.map(t => t.toString())
    })
      .then(({ data }) =>  data)
      .catch(errorNotification)
  }

  created() {
    this.checkChannels(this.model.targets.map(t => t.id))
      .then(res => {
        if (res) {
          this.frontendData = rawTargetsToFrontend(this.model.targets, res)
        }
      })
  }
}
