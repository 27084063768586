import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import { FrontendTargetData } from "@/components/Post/CheckChannelsList/types";
import { ForwardActionTarget } from "@/includes/types/Post.types";
import { CheckChannelsResponse } from "@/includes/services/FlowService";

export function rawTargetsToFrontend(targets: Array<ForwardActionTarget>, channelsInfos: CheckChannelsResponse['data']) {
    const iterableItemFactory = new IterableItemFactory()

    return targets.reduce((acc: Array<IterableListItem<FrontendTargetData>>, value) => {
        if (channelsInfos) {
            const findTarget = channelsInfos.find(d => +d.value === value.id)

            acc.push(iterableItemFactory.create({
                target: value,
                info: findTarget ?? null
            }))
        }

        return acc
    }, [])
}