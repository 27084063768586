var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-channels-list"},[_c('a-spin',{attrs:{"spinning":_vm.updateInProgress}},[_c('config-field',{attrs:{"title":_vm.$t('flow_subscription_check_channel_info_title'),"help-message":_vm.$t('flow_subscription_check_channel_info_help_message', [_vm.$store.getters.activeBoard.title])}},[_c('div',{staticClass:"py-2"},_vm._l((_vm.frontendData),function(target){return _c('check-channels-list-item',{key:target.guid,staticClass:"mb-2",attrs:{"target":target},on:{"remove":_vm.removeTarget,"edit":_vm.setToEdit,"retest":_vm.retest}})}),1)])],1),_c('add-entity-card',{staticClass:"w-full h-10",on:{"click":function($event){_vm.isTargetModalOpen = true}}}),_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"title":_vm.modalTitle,"footer":null,"after-close":_vm.resetState},model:{value:(_vm.isTargetModalOpen),callback:function ($$v) {_vm.isTargetModalOpen=$$v},expression:"isTargetModalOpen"}},[(_vm.viewState === 'editTarget' || _vm.viewState === 'targetInput')?_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.newTarget.value.target,
          'key': 'mode',
          'prefix': 'forward_action_',
          'options': _vm.forwardMessageActionModeOptions,
          'multiple': false,
          'clearable': false
        },
      }}}):_vm._e(),(
        _vm.viewState === 'targetSelectTopic' ||
          (_vm.viewState === 'editTarget' && _vm.newTarget.value.info && _vm.newTarget.value.info.status && _vm.newTarget.value.info.info.type === 'FORUM')
      )?[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.newTarget.value.target,
            'key': 'topic',
            'prefix': 'forward_action_',
            'allowClear': true
          },
        }}})]:_vm._e(),_c('div',{staticClass:"text-right"},[(_vm.viewState === 'targetSelectTopic' || _vm.viewState === 'editTarget')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSaveButtonClick}},[_vm._v(" "+_vm._s(_vm.viewState === 'targetSelectTopic' ? _vm.$t('add_button') : _vm.$t('save_button'))+" ")]):_vm._e()],1),(_vm.viewState === 'targetInput')?_c('config-field',{attrs:{"title":_vm.$t('check_channels_list_modal_target_data_input_title')}},[_c('a-input-group',{staticClass:"flex py-2",attrs:{"compact":""}},[_c('a-auto-complete',{staticClass:"w-full",on:{"select":_vm.selectOption},scopedSlots:_vm._u([{key:"dataSource",fn:function(){return _vm._l((_vm.queriedItems),function(channel){return _c('a-select-option',{key:channel.value,class:{'text-danger opacity-50': !channel.status},attrs:{"disabled":!channel.status}},[_vm._v(" "+_vm._s(channel.value)+" ")])})},proxy:true}],null,false,1416062347),model:{value:(_vm.targetValue),callback:function ($$v) {_vm.targetValue=$$v},expression:"targetValue"}}),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addTarget}},[_vm._v(" "+_vm._s(_vm.$t('add_button'))+" ")])],1)],1):_vm._e(),(_vm.errorTarget)?_c('a-alert',{staticClass:"mt-5",attrs:{"type":"error","message":_vm.errorTarget.error}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }